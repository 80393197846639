/*
export const someGetter = (state) => {
}
*/
export const getLiveChat = (state) => {
  return state.socket
}

export const getIsMiniGlobalbot = (state) => {
  return state.main.account.isMiniGlobalbot
}


export const getExpandedTags = (state) => {
  return state.main.expandedTags
}
